import React from "react";
// import { useNavigate  } from "react-router-dom";
import "../StylesServices/Services.css";
import operationlize from "../Assets/operationlize.png";
// import "../StylesServices/Operationalize.css";

function Operationalize() {
  // const navigate = useNavigate();
  // const handleBookAppointmentClick = () => {
  //   navigate("/ProductKnow");
  // };
  return (
    <div className="os-outer">
    <div className="os-section" id="operationalize">
  
      <div className="os-text-content">
      <p className="os-head">
        Operationalize
        </p>
  
        <h3 className="os-title">
          <span>Seamlessly implement AI solutions for scalable success.</span>
        </h3>
        <p className="os-description">
        Streamlining the deployment, monitoring, and scalability of machine learning models with robust MLOps, ensuring seamless integration and sustained performance
        </p>
  
       
        <button
            className="text-appointment-btn"
            type="button"
          >

            Know More
          </button>
      </div>
      <div className="os-image-content">
        <img src={operationlize} alt="sense" className="side-img-service"/>
      </div>
    </div>
    </div>
  );
}

export default Operationalize;

