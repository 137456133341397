import bgVideo from "../Assets/rotatingglobe.mp4";
import "../StylesAbout/Upper.css";

function Upper() {
  

  return (
    <div className="upper-outer">

    <div className="upper-section-container">
      <video autoPlay muted loop playsInline id="aboutbg-video"><source src={bgVideo} type="video/mp4" /></video>
      <div className="upper-hero-section">
       
        <div className="upper-text-section">
          <h2 className="upper-text-title">
         We are passionate about Data and Data Stories.<br/>And Hence we<br/> 
         EMPOWER YOUR BUSINESS WITH INSIGHTS FOR <br/>OUTCOMES
          </h2>

          
        </div>

       
      </div>

      
    </div>
    </div>
  );
}

export default Upper;
