import React from "react";
import "../StylesAbout/Office.css";

import "../StylesAbout/AboutForm.css";

function AboutForm() {
  return (
    <div className="aboutform-section" id="Aboutform">
      <div className="aboutform-title-content">
        <div className="aboutform-head-section">
          Get in Touch
        </div>
      <h1>Have a question or want to collaborate? We'd love to hear from you</h1>
      
      </div>


      
      <div className="aboutform-outer-section">
        <div className="aboutform-description">
        <form className="aboutform-content">
            <label>Name *</label>
            <input className="aboutform-input" placeholder="Name*"></input>

            <label>Email *</label>
            <input className="aboutform-input" placeholder="Email Address*"></input>
            
            <label>Organization *</label>
            <input className="aboutform-input" placeholder="Organization*"></input>
            
            <label>Job Title *</label>
            <input className="aboutform-input" placeholder="Job Title*"></input>

            <label>Phone Number *</label>
            <input className="aboutform-input" placeholder="Phone Number*"></input>

            <label>Project Type</label>
            <select className="aboutform-input" name="Projects" id="projects">
                <option value="Project type">Select Project Type</option>
                <option value="Web development">Web development</option>
                <option value="AI">AI</option>
                <option value="Machine learning">Machine learning</option>
                <option value="Data Science">Data Science</option>
              </select>

            <label>Message</label>
            <textarea className="aboutform-input" placeholder="Tell us about your project or enquiry.."></textarea>

            <div className="aboutform-btn-container">
            <button type="submit" className="aboutform-btn">Send Message</button>
            </div>
        </form>
        </div>

      </div>
    </div>
  );
}

export default AboutForm;

