import React from "react";

import BluePrint from "../ComponentProducts/BluePrint";
import CoPilot from "../ComponentProducts/Co-Pilot";
import HealthCare from "../ComponentProducts/HealthCare";
import Strategy from "../ComponentProducts/Strategy";
import Zero from "../ComponentProducts/zero";
import Zone from "../ComponentProducts/Zone";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function Product() {
  return (
    <div className="home-section">
      <Navbar />
      <Zero />
      <Zone />
      <BluePrint />
      <HealthCare />
      <Strategy />
      <CoPilot />
      <Footer />

    </div>
  );
}

export default Product;