import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";
import AidwiseBlack from "../Assets/Aidwise-Black.png"
import wplogo from "../Assets/wplogo.png";
import linkedin from "../Assets/Linkedin.png"
import gmail from "../Assets/Gmail.png";
import { useEffect, useState, useRef } from "react";

function Footer() {
  const [isVisible, setIsVisible] = useState(true);
  const footerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const footerRect = footerRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        setIsVisible(footerRect.top > viewportHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="footer-section">

    {isVisible && <div className="footer-top-btn-sc">
        <a href="https://wa.me/919205039383" className="wp-link-sc" target="_blank" rel="noopener noreferrer">
        <button className="wp-btn-sc">
          <img src={wplogo} alt="wplogo" className="wp-logo-sc"/>
       
        </button>
        </a>
      </div>}
    <div className="footer-top" ref={footerRef}>
      <div className="footer-top-content">
        <p>Ready to transfer your Business?</p>
      </div>
      <div className="footer-top-btn">
        
        <a href="https://wa.me/919205039383" className="wp-link" target="_blank" rel="noopener noreferrer">
        <button className="wp-btn">
          <img src={wplogo} alt="wplogo" className="wp-logo"/>
          Write Here
       
        </button>
        </a>
      </div>
    </div>
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
          <p className="ft-title">

              <img src={AidwiseBlack} alt="AidwiseBlack"/>
            </p>
            <p className="ft-description">
              At Adwise, we are committed to empowering businesses with cutting-edge AI
              solutions. Reach out to us for innovative strategies snd expert consultation to
              evaluate your business. 
              <br/>
              <br/>
              Connect with us for the latest insights and uodates in the AI industry.
            </p>
          </div>

          {/* <SubscribeNewsletter /> */}
        </div>
        
        <div className="ft-list-outer">

        <div className="ft-list">
          <p className="ft-list-title">Product</p>
          <ul className="ft-list-items">
            <li>
              <a href="/">Overview</a>
            </li>
            <li>
              <a href="/">Features</a>
            </li>
            <li>
              <a href="/">Solutions</a>
            </li>
            <li>
              <a href="/">Tutorials</a>
            </li>
            <li>
              <a href="/">Pricing</a>
            </li>
            <li>
              <a href="/">Releases</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Company</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/"}>About Us</Link>
            </li>
            <li>
              <Link to={"/"}>Careers</Link>
            </li>
            <li>
              <Link to={"/"}>Press</Link>
            </li>
            <li>
              <Link to={"/"}>News</Link>
            </li>
            <li>
              <Link to={"/"}>Media Kit</Link>
            </li>
            <li>
              <Link to={"/"}>Contact</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Resoures</p>
          <ul className="ft-list-items">
            <li>
              <a href="/">Blog</a>
            </li>
            <li>
              <a href="/">Newsletter</a>
            </li>
            <li>
              <a href="/">Events</a>
            </li>
            <li>
              <a href="/">Help Centre</a>
            </li>
            <li>
              <a href="/">Tutorials</a>
            </li>
            <li>
              <a href="/">Support</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Use cases</p>
          <ul className="ft-list-items">
            <li>
              <a href="/">Startups</a>
            </li>
            <li>
              <a href="/">Enterprise</a>
            </li>
            <li>
              <a href="/">Government</a>
            </li>
            <li>
              <a href="/">SaaS</a>
            </li>
            <li>
              <a href="/">Marketplaces</a>
            </li>
            <li>
              <a href="/">Ecommerce</a>
            </li>
          </ul>
        </div>


      </div>
        

      </div>

      <div className="ft-copyright">
        <p>© 2024 Aidwise.ai All trademarks are owned by Aidwise.ai, or used with permission</p>
        

        <ul className="ft-social-links">
          <li>
            Connect with us:
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/aidwise-ai?originalSubdomain=in"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
            
              <img src={linkedin} alt='linkedin-logo'></img>
            </a>
          </li>

          <li>
          <a
              href="mailto:swadeep_singh@aidwise.ai"
              title="Gmail"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={gmail} alt='gmail-logo'></img>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
