import React from "react";
// import "../StylesProducts/BluePrint.css";
import "../StylesProducts/OurProduct.css";
// import zeroshot from "../Assets/zero-shot.png";
// import circuitboard from "../Assets/circuit-board.mp4";
import stockbars from "../Assets/stock-bars.mp4";


function Zero() {
  return (
    <div className="ops-outer">
    <div className="ops-section" id="zero">
  
      <div className="ops-text-content">
      <p className="ops-head">
          ZERO SHOT LEARNING
        </p>
  
        <h3 className="ops-title">
          <span>Transforming Predictions With Minimal Data</span>
        </h3>
        <p className="ops-description">
        At Adwise, we offer cutting-edge Zero Shot Learning solutions that empower businesses to deploy Ai model without the need for extension labeled datasets.
        </p>
        <p className="ops-description"> 
        Perfect for industries where labeled data is scarce, like healthcare and finance, our solutions provide accurate insights and faster implementation, minimizing, the need for costly data labeling.
        </p>
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
      <div className="ops-image-content">
        <video autoPlay muted loop playsInline id="op-video" className="sides-img"><source src={stockbars} type="video/mp4" /></video>
        {/* <img src={zeroshot} alt="zero-shot" className="sides-img"/> */}
      </div>
    </div>
    </div>
  );
}

export default Zero;

