import React from "react";
// import "../StylesProducts/BluePrint.css";
import sense from "../Assets/sense.png";
// import "../StylesProducts/OurProduct.css";
import meeting from "../Assets/meeting.mp4";

function Strategy() {
  return (
    <div className="ops-outer">
    <div className="ops-section" id="strategy">
  
      <div className="ops-text-content">
      <p className="ops-head">
        STRATEGY
        </p>
  
        <h3 className="ops-title">
          <span>Strategic AI Consulting to Transfer Your Business</span>
        </h3>
        <p className="ops-description">
        Partner with our experts to develop and implement a robust Al strategy tailored to your business goals.

        </p>
        <p className="ops-description">

        We'll help you identify opportunities, optimize processes, and integrate Al solutions that drive innovation and enhance your competitive edge. 
        </p>
  
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
      <div className="ops-image-content">
        <video autoPlay muted loop playsInline id="op-video" className="sides-img"><source src={meeting} type="video/mp4" /></video>
      </div>
    </div>
    </div>
  );
}

export default Strategy;

