import React from "react";
import { useNavigate  } from "react-router-dom";
import "../StylesServices/Services.css";
import differentiateAI from "../Assets/differentiate-ai.svg";
// import "../StylesServices/DifferentiateAI.css";

function DifferentiateAI() {
  const navigate = useNavigate();
  const handleBookAppointmentClick = () => {
    navigate("/ProductKnow");
  };
  return (
    <div className="os-outer">
    <div className="os-section" id="differentiateai">
  
      <div className="os-text-content">
      <p className="os-head">
      Differentiate AI
        </p>
  
        <h3 className="os-title">
          <span>Leverage advanced AI methods to stand out in the market</span>
        </h3>
        <p className="os-description">
        Addressing the challenge of generic AI solutions by delivering bespoke, differentiated AI tailored to your specific needs, unlocking unique value and sustainable competitive advantage
        </p>
  
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
      <div className="os-image-content">
        <img src={differentiateAI} alt="blueprint" className="side-img-service"/>
      </div>
    </div>
    </div>
  );
}

export default DifferentiateAI;

