import React from "react";
// import "../StylesProducts/BluePrint.css";
import "../StylesProducts/OurProduct.css";
import blueprint from "../Assets/BlueprintPhoto.png";
import meeting from "../Assets/meeting.mp4";

function BluePrint() {
  return (
    <div className="ops-outer">
    <div className="ops-section" id="blueprint">
  
      <div className="ops-text-content">
      <p className="ops-head">
      DOMAIN BLUEPRINT
        </p>
  
        <h3 className="ops-title">
          <span>Customized AI Solutions for your Unique Industry Needs</span>
        </h3>
        <p className="ops-description">
        We analyze your unique business landscape and design comprehensive Al strategies that align with your goals.
  
        </p>
        <p className="ops-description">
        Whether you're in healthcare, finance, retail, or manufacturing, we provide custom Al models and frameworks that are scalable and adaptable to your needs.  
        </p>
  
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
      <div className="ops-image-content">
        {/* <video autoPlay muted loop playsInline id="op-video" className="sides-img"><source src={meeting} type="video/mp4" /></video> */}
        <img src={blueprint} alt="blueprint" className="sides-img"/>
      </div>
    </div>
    </div>
  );
}

export default BluePrint;

