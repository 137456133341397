import React from "react";
import "../StylesServices/Services.css";
// import { useNavigate  } from "react-router-dom";
import engineerData from "../Assets/engineer-data.webp";
// import "../StylesServices/EngineerData.css";

function EngineerData() {
  // const navigate = useNavigate();
  // const handleBookAppointmentClick = () => {
  //   navigate("/ProductKnow");
  // };
  return (
    <div className="os-outer">
    <div className="os-section" id="engineerdata">
      <div className="os-image-content">
        <img src={engineerData} alt="data-zone" className="side-img-service"/>
      </div>
  
      <div className="os-text-content">
      <p className="os-head">
        Enginner Data
        </p>
  
        <h3 className="os-title">
          <span>Discover how we transform data into actionable insights</span>
        </h3>
        <p className="os-description">
        Transforming data from source to consumption with fit-for-purpose architectures, analytics data models, and deep understanding, ensuring high-quality, AI-ready data.
       </p>

  
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
    </div>
    </div>
  );
}

export default EngineerData;

