import React from "react";
import "../StylesAbout/Office.css";
import hyderabad from "../Assets/hyderabad.png"
import noida from "../Assets/noida.png"

function Office() {
  return (
    <div className="office-section" id="Office">
      <div className="office-title-content">
      <h1>Solving Global Challenges</h1>
      <h3>We Currently Operating from These Locations</h3>
      
      </div>

    

      <div className="office-address-outer">
      <div className="office-address-section">
        <div className="office-img-section">
          <img className="office-img" src={hyderabad}></img>
        </div>
        <div className="office-description">
        {/* <span style={{fontWeight: 'bolder'}}>Hyderabad</span> */}

        <h6 className="office-description-title">Address:</h6>
        <p className="office-description-content">Aidwise Analytica Pvt Limited</p>

        <h6 className="office-description-title">Location:</h6>
        <p className="office-description-content">Plot No 1/C, Sy No 83/1, Raidurgam panmaktha</p>

        <h6 className="office-description-title">Area:</h6>
        <p className="office-description-content">Hyderabad Knowledge City, Serilingampally</p>

        <h6 className="office-description-title">Pin Code:</h6>
        <p className="office-description-content">Telangana 500081</p>
        </div>
      </div>

      <div className="office-address-section">
        <div className="office-img-section">
          <img className="office-img" src={noida}></img>
        </div>
        <div className="office-description">
        {/* <span style={{fontWeight: 'bolder'}}>Noida,</span> */}
        <h6 className="office-description-title">Address:</h6>
        <p className="office-description-content">Aidwise Analytica Pvt Limited</p>

        <h6 className="office-description-title">Location:</h6>
        <p className="office-description-content">1739, 16th Floor Block J, Sportswood</p>

        <h6 className="office-description-title">Area:</h6>
        <p className="office-description-content">Sector 79, Noida, Gautam Buddha Nagar</p>

        <h6 className="office-description-title">Pin Code:</h6>
        <p className="office-description-content">UP-201301</p>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Office;
