import React from "react";
import "../StylesAbout/OurVision.css";
import founder from "../Assets/founder.png"
import excellence from "../Assets/excellence.png"
import innovative from "../Assets/innovative.png"
import integrity from "../Assets/integrity.png"
import collaboration from "../Assets/collaboration.png"

function OurVision() {
  return (
    <div className="ov-section" id="Ourvision">
      <div className="ov-title-content">
      <div className="ov-title-contentheading">
      <h1>Our Vision</h1>
      </div>
      

        <p className="ov-description">
        We are an Applied Analytics and Data Science solutions and services company that aims to bridge the gap between technological advancements and business value generation, primarily for the healthcare industry. We believe insight-generating analytics can turbocharge a company's performance beyond its investment.

       </p>
      </div>
      <div className="ov-leadership-title-content">
      <div className="ov-title-contentheading">
      <h1>Our LeaderShip</h1>
      </div>
        

        <p className="ov-leadership-description">
        <ul className="ov-leadership-list">
        <li className="ov-leadership-item">
        <img src={integrity} alt="sign" className="ov-leadership-img"/>
        <h4 className="ov-leadership-item-head">Expertise That Drives Innovation:</h4> 
        Led by an IIM Bangalore graduate with extensive experience in Al and data science, including leadership roles at a Fortune 5 company.
        </li>

        <li className="ov-leadership-item">
        <img src={innovative} alt="sign" className="ov-leadership-img" />
        <h4 className="ov-leadership-item-head">Established Al Excellence:</h4>
        Built and scaled Al capabilities across 30+ teams, delivering transformative business outcomes globally.
        </li>
        <li className="ov-leadership-item">
        <img src={excellence} alt="sign" className="ov-leadership-img" />
        <h4 className="ov-leadership-item-head">Patented Innovator:</h4>
        Holder of 5+ patents, demonstrating a commitment to cutting-edge solutions and industry leadership.
        </li>
        <li className="ov-leadership-item">
        <img src={collaboration} alt="sign" className="ov-leadership-img" />
        <h4 className="ov-leadership-item-head">Real-World Impact:</h4> 
        Guided businesses across diverse industries, translating complex data challenges into measurable success stories.
        </li>
        </ul>
        </p>
      </div>

      
      {/* Founder Details Section */}

      <div className="founder-section">
        <div className="founder-img-section">
          <img className="founder-img" src={founder}></img>
        </div>
        <div className="founder-description">
        <h1>Founder Profile</h1>
          <ul className="founder-list">
            <li className="founder-list-item"><span className="founder-list-sign">&#129174;</span>  Founder, Aidwise (Vision: Bridging Al and Outcomes Gap though Al services)</li>
            <li className="founder-list-item"><span className="founder-list-sign">&#129174;</span>  US Patent in Clinical Workflow, 40U40 Data Scientist</li>
            <li className="founder-list-item"><span className="founder-list-sign">&#129174;</span>  IIM Bangalore- MBA | London Business School- Executive</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OurVision;
