import React from "react";
import StatsCard from "./StatsCard";
import "../Styles/ProjectInfo.css";


function ProjectInfo() {
  return (
    <div className="projectinfo-outer">
    <div className="projectinfo-section" id="projectinfo">
      <div className="projectinfo-title-content">
        <h3 className="projectinfo-title">
          <span>We Offer <mark className="hlt">Al-Awarded Expertise</mark>, Country Level <br/>Nominated Al Solutions, Powered Startup Funding, <br/>and <mark className="hlt">Innovation at the Core.</mark></span>
        </h3>

        <p className="projectinfo-description">
        partner with us to leverage cutting edge Al "Expertise". From startups to established enterprises, we empower <br/>innovation and growth at every stage.
        </p>
      </div>

      <div className="stats-cards-content">
        <StatsCard
          name="Clients"
          digit="12+"
        />
        <div className="vertical"> </div>
        <StatsCard
          name="Team"
          digit="20+"
        />
        <div className="vertical"> </div>
        <StatsCard
          name="No. of projects"
          digit="100+"
        />
        <div className="vertical"> </div>
        <StatsCard
          name="Positive ratings"
          digit="98+"
        />
      </div>
    </div>
    <div className="ellipse"></div>
    </div>
  );
}

export default ProjectInfo;
